<template>
  <div>
    <Campaign @updateSearch="searchText = $event"/>
    <calendar v-if="['calendar'].includes($route.name)" />
    <list v-if="['list', 'campaigns'].includes($route.name)" :search="searchText"/>
    <sendout v-if="['sendouts'].includes($route.name)" :search="searchText"/>
    <stats v-if="['stats'].includes($route.name)" :search="searchText" />
  </div>
</template>

<script>
import Calendar from "./Calendar.vue";
import List from "./List.vue";
import Sendout from './Sendout.vue';
import Campaign from "@/components/core/CampaignNav";
import Stats from './Stats.vue';

export default {
  components: { 
    Calendar, 
    List,
    Sendout,
    Campaign,
    Stats
  },
    name: 'Campaigns',
    data: () => ({
      searchText: '',
    }),

    methods: {
   
    }
  }


</script>
