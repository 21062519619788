<template>
  <div class="pb-3">
    <v-bottom-navigation
      id="spacing"
      class="justify-sm-start mb-8"
      height="60"
      :value="value"
    >
      <v-icon
        class="mt-6 mr-2 font-weight-black"
        dark
        color="black"
      >
        mdi-email-outline
      </v-icon>
      <div class="title pt-7">
        Campaigns
      </div>
      <v-divider
        class="my-7 ml-6"
        width="5%"
        vertical
      ></v-divider>
      <div class="mx-6 my-8">
        <router-link
          class="mx-4" 
          style="text-decoration: none; color: inherit;"
          v-for="link in links"
          :key="link.name"
          :to="link.to"
          @mouseover="hovered = true" @mouseleave="hovered = false"
        >
          <span id="idName" class="link black--text">{{ link.name }}</span>
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="my-6">
        <v-row class="ml-6">
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              @input="$emit('updateSearch', $event)"
              id="search"
              class="w-10 link"
              append-icon="mdi-magnify"
              placeholder="Search"
              dense
              solo
              flat
            ></v-text-field>
          </v-col>        
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-btn
              id="calendar"
              class="mr-2"
              dark
              icon
              depressed
              tile            
              :to="'/campaigns/calendar'"
            >
              <v-icon
                dark
                color="white"
              >
                mdi-calendar-outline
              </v-icon>
            </v-btn>
            <v-btn
              id="campaign"
              dark
              icon
              depressed
              tile
              @click="newCampaign()"
            >
              <v-icon
                dark
                color="white"
              >
                mdi-email-plus-outline
              </v-icon>
            </v-btn> 
          </v-col>
        </v-row>                      
      </div>
    </v-bottom-navigation>
    <new-campaign
      v-if="addCampaign"
      :template="template"
      :tags="tags"
      :pays="pays"
      :templateID="templateID"
      :creas="creas"
      :stepCampaign="step"
      :databases="databases"
      :selectedDb="dbSelected"
      :createModal="createModal"
      @close="addCampaign = false"
    />
  </div>
</template>

<script>
import NewCampaign from '../campaign/newCampaign.vue';

export default {
  name: 'NavCampaign',
  components: {
    NewCampaign
  },
  data() {
    return {
    value: 0,
    step: 0,
    dbSelected: 0,
    search: '',
    hovered: false,
    addCampaign: false,
    createModal: false,
    links: [
      {
        name: "HTML List",
        to: '/campaigns/list'
      },
      {
        name: "Sendouts",
        to: '/campaigns/sendouts'
      },
      {
        name: "Stats",
        to: '/campaigns/stats'
      },
    ],
    templateID: null,
    template: {
      campaign_name: "",
      agency: "",
      country: "",
      creator_id: this.$store.state.auth.user.id,
      designer_id: null,
      geo_filter: "",
      text_version: "",
      comment: "",
      credits: 1,
    },
    tags: [],
    pays: [
      {
        payout: "",
        idCurrency: 1,
        idPaymodel: "",
      },
    ],
    creas: [],
    databases: []
  }},

  methods: {
    newCampaign() {
      this.step = 0
      this.dbSelected = 0
      this.templateID = null,
      this.template = 
      {
        campaign_name: "",
        agency: "",
        country: null,
        creator_id: this.$store.state.auth.user.id,
        designer_id: null,
        geo_filter: "",
        text_version: "",
        comment: "",
        credits: 1,
      },
      this.tags = this.tags;
      this.pays = this.pays;
      this.creas = this.creas;
      this.createModal = true;
      this.addCampaign = true;
    }
  }

}
</script>

<style scoped>

.title {
  font-style: normal;
  font-weight: 900 !important;
  font-size: 24px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em !important;
}

.link {
  font-style: normal;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-size: 0.85rem !important;
}

.router-link-exact-active {
  font-style: black !important;
  font-weight: 900 !important;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #000000;
  line-height: 15pt;
  border-radius: 2px;
}

#spacing {
  padding-left: 2%;
}

.v-divider--vertical{
  border-width: 2px !important;
  border-color: #000000 !important;
  min-height: 50%;
  height: auto;
}

#calendar {
  background-color: #000000 !important;
  border-radius: 5px;
}

#campaign {
  background-color:  #F85182 !important;
  border-radius: 5px;
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 38px;
  height: 38px;
}

#search .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.theme--light .v-bottom-navigation {
  color: #000000 !important;
  background-color: #F5F5F5 !important;  
}

.v-item-group.v-bottom-navigation{
  box-shadow: none;
}

.link .v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #000000;
}

.calendar-icon {
  font-size: 20px !important;
}

</style>
